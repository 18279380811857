import React from 'react';
import Layout from '../../layout/pl';
import TeamPerson from '../../components/TeamPerson';

import Paulina from '../../images/team/paulina-small.png';
import cert1 from "../../images/certyfikaty_paulina/1.jpg"
import cert2 from "../../images/certyfikaty_paulina/2.jpg"
// import cert3 from "../../images/certyfikaty_paulina/3.jpg"
import cert4 from "../../images/certyfikaty_paulina/4.jpg"
import cert5 from "../../images/certyfikaty_paulina/5.jpg"
import cert6 from "../../images/certyfikaty_paulina/6.jpg"
import cert7 from "../../images/certyfikaty_paulina/7.jpg"
import cert8 from "../../images/certyfikaty_paulina/8.jpg"
import cert9 from "../../images/certyfikaty_paulina/9.jpg"
import cert10 from "../../images/certyfikaty_paulina/10.jpg"
import cert11 from "../../images/certyfikaty_paulina/11.jpg"
import cert12 from "../../images/certyfikaty_paulina/12.jpg"
import cert13 from "../../images/certyfikaty_paulina/13.jpg"
import cert14 from "../../images/certyfikaty_paulina/14.jpg"
import cert15 from "../../images/certyfikaty_paulina/15.jpg"
import cert16 from "../../images/certyfikaty_paulina/16.jpg"
import cert17 from "../../images/certyfikaty_paulina/17.jpg"
import cert18 from "../../images/certyfikaty_paulina/18.jpg"

const handleClick = () => {
	console.log('click');
	window.medicall_modal('d52b1035873e7764d371');       
	return false;
};
const certification = [ cert1,cert2,cert4,cert5,cert6,cert7,cert8,cert9,cert10,cert11,cert12,cert13,cert14,cert15,cert16,cert17,cert18 ];

const PaulinaGesich = (props) => {
	return (
		<Layout {...props}>
			<TeamPerson img={Paulina} handleClick={handleClick} certification={certification} person="paulina" />
		</Layout>
	);
};
// }
export default PaulinaGesich;


// {/* <TitleHeader title="lek. dent. Paulina Gęsich" />
// 			<div className="content zespol-template">
// 				<div className="img-contener">
// 					<img src={Paulina} className="img" alt="Paulina" />
// 				</div>

// 				<div className="text-contener">
// 					<h1>lek. dent. Paulina Gęsich</h1>
// 					<p className="under-h1">lekarz dentysta</p>
// 					<img
// 						src="https://sztukastomatologii.pl/wp-content/uploads/2018/07/zapraszam-300x89.png"
// 						alt="podpis"
// 						className="img podpis"
// 					/>
// 					<p>
// 						Absolwentka kierunku lekarsko- dentystycznego Collegium Medicum Uniwersytetu Jagiellońskiego w
// 						Krakowie. Wykonuje zabiegi z zakresu leczenia kanałowego pierwotnego oraz powtórnego, usuwanie
// 						złamanych narzędzi i wkładów koronowo – korzeniowych, zamykania perforacji. Wszystkie zabiegi z
// 						zakresu endodoncji wykonuje w powiększeniu mikroskopu zabiegowego. Jest uczestnikiem i
// 						absolwentką licznych szkoleń, konferencji oraz zjazdów z zakresu endodoncji mikroskopowej,
// 						odbudowy estetycznej zębów, medycyny estetycznej i okluzji oraz leczenia starcia zębów. Jest
// 						absolwentką studiów podyplomowych Medycyny Estetycznej dla lekarzy Krakowskiej Wyższej Szkoły
// 						Promocji Zdrowia. Prywatnie pasjonuje się fotografią .Miłośniczka podróżowania i dobrej kuchni.
// 					</p>
// 					<button className="btn btn-register" type="button" onClick={handleClick}>
// 						Zarejestruj się
// 					</button>
// 				</div>
// 			</div>
// 			<GalleryComponent className="content" img={certification} /> */}